import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'Numbers',
    data () {
        return {
            numbers: {}
        }
    },
    components: {
        adminLayout
    },
    mounted() {
        this.getNumbers()
    },
    methods: {
        getNumbers() {
            const self = this;
            const sortByAsc = '?sortByAsc=name';

            httpAxios({
                url: this.$backendUrl + '/api/v1/numbers' + sortByAsc,
                method: 'GET'
            }).then(response => {
                self.numbers = response.data.rows;
            })
        }
    }
}
